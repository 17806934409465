<template>



<div class="main">

<div class="ai-toggle" @click="changeModel()"> {{ this.model }}</div>

<transition name="fade">

<div v-if="this.modalShown" class="modal" @click="hideModal()">

<div class="window center" @click="hideModal()">

  <div class="top-bar">

    {{ modalTitle }}

  </div>

  <div class="loading-container">
            <div class="loading" v-if="this.aiActive"></div>
  </div>

  <div class="message" v-if="this.modalMessage != ''">

    {{ modalMessage }}

  </div>



</div>


</div>

</transition>

<!--{{ dates }}-->

<div class="sidebar">


<div class="hamburger" @click="this.sendModal('Stay tuned','These features are still being built. Stay tuned!')">
<div class="bar"></div>
<div class="bar"></div>
<div class="bar bar-bottom"></div>
</div>

<div class="name">
Scott M. Gilmore 

</div>    

<div class="timeline">

<div v-for="(date, index) in this.dates" :key="index">

  <div v-if="(index > 0) && (year(this.dates[index].date) !== year(this.dates[index-1].date))" class="event year"> {{ year(this.dates[index-1].date) }}  </div>

<div v-if="(index > 0) && (month(this.dates[index].date) !== month(this.dates[index-1].date))" class="event month"> {{ month(this.dates[index-1].date) }}  </div>






<div class="event fade-background" @click="changeContent(); this.selected = index; this.eventsMessage = ''; generateEvents(this.dates[index].data)" :class="{ selected: (this.selected == index) }">
<div class="marker"></div>

<div class="event-name">

{{ shortenString(date.panel || date.test || "Unnamed Event", 20) }}

</div>
<div class="event-date">
{{ monthDay(date.date) }}
</div>

</div>

</div>

</div>



</div>

<transition name="fade">

<div class="content" v-if="showContent" >

<span class="title">{{ dates[this.selected].panel || dates[this.selected].test || null }}</span> ✎
<p></p>

<span class="heading">Linked Data</span><br>
<br>


<span v-for="test in dates[this.selected].data" :key="test.name">

<span class="col" :class="{ abnormal: (isAbnormal(test)) }"><b> {{ isAbnormal(test) ? "Abnormal" : "Normal" }}</b></span> 
<span class="col">{{ shortenString(test.name, 30) }}</span>
<span class="col"> {{ shortenString(test.value,10) }} {{ " " + test.units }}</span> <span class="explain" @click="explain(test)">Explain 💬</span>
<span class="explain" style="background: green" @click="sendModal('Graph','Feature coming soon!')">Graph 📈</span>
<br>
</span>

<p></p>
<span class="heading">Notes ✎</span><br>
<br>
Dr. Jung ordered these tests because I was having some abdominal pain. Pain has since gone away. I think it was due to starting to eat gluten again, or possibly job stress.

<div class="inset">

    🧭 <span class="heading">Associated Events</span>

    <div class="loading-container">
            <div class="loading" v-if="this.eventsLoading"></div>
    </div>
    
   <div class="events-message" v-html="this.eventsMessage"></div>

</div>



</div>

</transition>
</div>


</template>

<script>


import moment from 'moment';


const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
              apiKey: "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", //impathiq 4/14/23
});
const openai = new OpenAIApi(configuration)


async function queryGPT(context, prompt, model, temperature) {
            
            console.log("Querying GPT: " + context + " " + prompt + " " + model + " " + temperature )

            try {
            const completion = await openai.createChatCompletion({
                model: model,
                temperature: temperature,
                "messages": [{"role": "system", "content": context},{"role": "user", "content": prompt}]
            });

            var reply = completion.data.choices[0].message.content;
            return reply
            } catch (error) {
                console.log(error)
                alert('OpenAI Error: ' + error)

            }
}


// load dates from JSON file

import json from '../data/patient1.json'

function compareByDate(a, b) {
  return b["date"].localeCompare(a["date"]);
}

var sorted = json["dates"].sort(compareByDate)


export default {
        name: 'SidekickView',
        data() {
            return {
              modalMessage: "",
              modalTitle: "",
              showContent: true,
              modalShown: false,
              selected: 0,
              dates: sorted, 
              aiActive: false,
              model: "gpt-3.5-turbo",
              modelIndex: 0,
              modelList: ["gpt-3.5-turbo", "gpt-4"],
              eventsMessage: "",
              eventsLoading: false
            }
},

methods: {


    sendModal(title, text) {
      this.modalTitle = title
      this.modalMessage = text
      this.showModal()
    },


    changeModel() {
      this.modelIndex += 1
      if (this.modelIndex > this.modelList.length - 1) {
        this.modelIndex = 0
      }
      this.model = this.modelList[this.modelIndex]
    },

    explain(data) {

      this.modalMessage = ""
      this.modalTitle = "AI Explanation"
      this.aiActive = true

      this.showModal()

      var stringData = JSON.stringify(data)

      queryGPT("Explain the clinical significance fo the following health care data. Limit your response to 200 characters.  Speak in the 2nd person, i.e. 'your data indicates...' Reference how the data could relate to symptoms or the way the patient feels.", stringData, this.model, 0.9).then((reply) => {
        this.aiActive = false
        this.modalMessage = reply
      })      


    },

    generateEvents(data) {
        
        this.eventsMessage = ""
        this.eventsLoading = true

        var stringData = JSON.stringify(data)
        stringData = ""

        queryGPT("Generate a bulleted list of 5 specific personal life events (imagine names of people, places, and events) that could theoretically be extracted from a user's calendar (events, days of month and week) and that could relate to the patient's health and presented clinical data, with two <br><br> tags in plain text before and after each. Only include information that is typically found in calendar entries. End each line with a relevant emoji. Example: <br><br>• You returned from vacation in Mexico the previous week. <br><br>• You had a yoga class in the morning.<br><br>• This was your first Monday back from vacation.", stringData, this.model, 0.9).then((reply) => {
          this.eventsMessage = reply
          console.log(reply)
          this.eventsLoading = false
          console.log(this.eventsLoading)
          console.log(this.eventsMessage)
        })
        
    },

    showModal() {
      this.modalShown = true;
    },

    hideModal() {
      this.modalMessage = "";
      this.modalShown = false;
    },

    changeContent() {
      this.showContent = false;
      setTimeout(() => {
        this.showContent = true;
      }, 150); //
    },


    isAbnormal(test) {

        if (test.referenceRange && test.referenceRange != test.value) {
            return true
        }

        if (test.referenceRangeHigh && test.value > test.referenceRangeHigh){
            return true
        }

        if (test.referenceRangeLow && test.value < test.referenceRangeLow) {
            return true
        }

        return false

    },

    shortenString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + "...";
    }
    return str;
}, 

   year(value) {
     return moment(value).format('YYYY');
   },

    month(value) {
      return moment(value).format('MMMM');
    },

   monthDay(value) {
      return moment(value).format('MMM Do');
    },

  parseStringToJSON(string) {
    var json = JSON.parse(string)
    return json
  },


}
}


</script>

<style scoped>


body {
    background: rgb(4, 1, 32);
    
}

.main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px auto;
    max-width: 1000px;
    text-align: left;

    font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;    

}

.sidebar {
    background: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0px 20px 20px;
    height: 100vh;
    position: relative;
}

.content {
    padding: 20px;
    background: white;
    width: 100%;
    height: auto;
    margin: 20px;
    border-radius: 5px;
    min-height: 80vh;
    opacity: 1;
    transition: opacity 0.3s ease;
    #box-shadow: 0 0 20px #ddd;
}


.event {
    width: 200px;
    padding: 20px 20px 20px 20px;
    

    border-left: 1px solid rgb(255,255,255,0.3);


    border-radius: 0px 7px 7px 0px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;
    position:relative;
    margin-left: 3px;

}

.event-date {
    text-align: left;
    opacity: 0.5;
}

.event-name {
    text-align: right;
}

.name {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    position: absolute;
    top: 15.5px;
    left: 50px;
}

.marker {
    width: 6px;
    height: 6px;
    position: absolute;
    top: calc(50%-5px);
    left: -3px;
    background: white;
    border:none;
    border-radius: 100px;
    z-index: 100;
}

.selected {
    background-color: rgb(255,255,255,0.3)
}

.year {
    font-size: 30px;
    color: rgb(255,255,255,0.5);
    flex-direction: row;
    background: rgb(255,255,255,0.2);
    height: 10px;
}
.month {
    font-size: 20px;
    color: rgb(255,255,255,0.5);
    flex-direction: row;
    background: rgb(255,255,255,0.1);
    height: 0px;
}
.title {
    font-weight: 900;
    font-size: 20px;
    
}

.explain {
    font-size: 10px;
    color: white;
    background: rgb(0, 85, 255);
    text-decoration:none;
    padding: 3px;
    border-radius: 6px;
    margin: 3px;
    position: relative;
    top: -2px;
    display: inline-block;
}

.heading {
    opacity: 0.5;
    font-size: 12px;
    text-transform: uppercase;
}

.hamburger {
    width: 20px;
    height: 20px;

}

.bar{
    width: 100%;
    height: 2px;
    background: white;
    margin-bottom: 3px;
    border-radius: 20px;
}

.bar-bottom {
    margin-bottom: 0px;
}

.inset {
    font-size: 14px;
    border: 2px solid rgb(11, 0, 91);
    background: rgb(11,0,91,0.1);
    padding: 20px 20px;
    border-radius: 10px;
    margin: 30px auto;
    position: relative;
}

.inset > .heading {
    opacity: 0.9;
    font-weight: 300;
}

.timeline {
    margin-top: 20px;
    height: 100vh;
    overflow-y: auto;
}

.abnormal {
    color: rgb(255,0,0,1);
    font-weight: 900;
}

.col {
    display: inline-block;
    width: 150px; /* or 'block' depending on your layout needs */
}
.fade-background {
    transition: background-color 0.3s ease;
}

.invisible {
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.window {
  width: 500px;
  height: auto;
  min-height: 300px;
  background: white;
  border-radius: 0px 0px 10px 10px;
  #box-shadow: 0 0 20px #ddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 900;
  color: rgb(4, 1, 32);
  z-index: 1000;
  border-bottom: 1px solid rgb(4, 1, 32, 0.5);
  border-left: 1px solid rgb(4, 1, 32, 0.5);
  border-right: 1px solid rgb(4, 1, 32, 0.5);
  transition: height 0.3s ease;

  
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(4, 1, 32, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  transition: opacity 0.3s ease;
}

.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
}

  .loading {
    
    display: inline-block;
    width: 80%;
    height: 80%;
    border: 3px solid rgba(100, 100, 100, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }

  .message {
    font-size: 18px;
    font-weight: 300;
    width: 60%;
    height: auto;
    margin: 20px auto;
    padding: 40px 20px;
  }

  .top-bar {
    border-top: 1px solid rgb(4, 1, 32, 0.5);
    border-left: 1px solid rgb(4, 1, 32, 0.5);
    border-right: 1px solid rgb(4, 1, 32, 0.5);
    position: absolute;
    top: -40px;
    left: 0;
    width: 460px;
    height: 40px;
    text-align: left;
    border-radius: 10px 10px 0px 0px;
    background: rgb(4, 1, 32, 0.7);
    font-size: 14px;
    color: rgb(255,255,255,0.7);
    font-weight: 300;
    padding: 0px 20px;
    line-height: 40px;
    text-transform: uppercase;

  }

  .ai-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    opacity: 0.5;
    text-align: left;
  }

  .events-message {
    width: 70%;
    margin: 0px auto;}
</style>
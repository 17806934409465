
import { createWebHistory, createRouter } from 'vue-router';
import LoginView from "@/views/LoginView.vue"
import MainView from "@/views/MainView.vue"

const routes = [
        {
            path: '/',
            redirect: {
                name: "login"
            },
            component: LoginView,
            props: true
        },
        
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            props: true

        },
        {
            path: '/main-view',
            name: 'main-view',
            component: MainView,
            props: true

        }
        
    ];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;


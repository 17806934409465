<template>
    <div id="login">
		<div class="login-modal">
			<img class="logo-img" src="../assets/eigenta-purple.svg">
			<div class="or">Enter your details to sign in to your account:</div>
			<div>
				<input type="text" name="username" v-model="input.username" placeholder="Email" />
                <input type="password" name="password" v-model="input.password" placeholder="Password" />
				<div class="or">Don't have an account? <a href="mailto:signup@eigenta.com" style="margin-left:2px">Request Now</a></div>
                <button type="button" class="signin-button" v-on:click="login()">Login</button>
	
				<!-- <div class="or">or sign in with:</div>
				<div class="social">
					<img class="social-img" src="assets/facebook.svg">
					<img class="social-img" src="assets/apple.svg">
					<img class="social-img" src="assets/google.svg">
				</div>
			-->
				<a href="mailto:help@gliant.com" style="font-weight: 100"><div class="or">Having trouble signing in?</div></a>
                </div>
				
		</div>
		<div class="copyright">
			© 2024 Eigenta, Inc. All rights reserved.
            
            <!-- <script type="text/javascript">document.write( new Date().getFullYear() );</script> -->
            
            
			</div>
    </div>
</template>
<script>
    export default {
        name: 'LoginView',
        data() {
            return {
                input: {
                    username: '',
                    password: ''
                },
                users: {
                    'test': 'test',
                    
                    
                }
      
            }
        },
        methods: {
            login() {
                if(this.input.username != "" && this.input.password != "") {
                    if(this.input.username in this.users && this.input.password == this.users[this.input.username]) {
                        this.$emit('authenticated', true);
                        this.$emit('username', this.input.username);
                        
                        this.$router.replace({name: "main-view"});
                        
                        
                    } else {
                        alert('Invalid username or password. Please try again or contact help@eigenta.com for assistance.');
                    }
                }
                else {
                    alert('Please enter a username and password');
                }
            }
        }
    }
</script>

<template>
  <div id="app">
    <div id="nav">
      
      <transition v-if="this.mode!='development'" name="fade" mode="out-in">
        <div v-if="authenticated">logged in as {{ username }}.</div>
      </transition>
      
      <!--
      <div v-if="this.mode=='development'">
      Views: <br> <select id="routeDropdown" class="dropdown" v-model="selectedRoute">
        <option v-for="route in this.$router.getRoutes()" :value="route.path" :key="route.path" > {{ route.name }} </option>
      </select>
      </div>
      Prompt Macros: <br> <select id="promptDropdown" class="dropdown" v-model="selectedPrompt" @change="copyContent()">
        <option v-for="prompt in this.prompts" :value="prompt" :key="prompt" > {{ prompt }} </option>
      </select>
      -->


      <transition v-if="this.mode!='development'" name="fade" mode="out-in">
        <router-link v-if="authenticated" to="/login" v-on:click="logout()" replace>Logout</router-link>
      </transition>

      
  </div>

    <router-view v-slot="{ Component }" @authenticated = "setAuthenticated" @username = "setUsername" >
      <transition name="fade" mode="out-in">
        <component :is="Component" /> 
      </transition>
    </router-view>

  </div>

</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      authenticated: false,
      username: 'none',
      selectedRoute: 'none',
      selectedPrompt: 'none',
      mode: process.env.VUE_APP_MODE,
    }
  },


  watch: {
    selectedRoute(newRoute) {
      this.$router.push(newRoute);
    },
  },

  mounted() {

    console.log(`Running in ${this.mode} mode`);


    console.log("Selected: " + this.selectedRoute)


    if( this.mode == 'development'){
      this.authenticated=true;
      this.username="dev";
      console.log("Development Mode")
      console.log("Username: " + this.username)
      this.$router.replace({name: "main-view"})
    }

    if(!this.authenticated) {
      this.$router.replace({name: "login"});
    }
  },


  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    setUsername(name) {
      this.username = name;
    },
    logout() {
      this.authenticated = false;
    },

    async copyContent() {
    try {
      await navigator.clipboard.writeText(this.selectedPrompt);
      console.log("'" + this.selectedPrompt + "' copied to clipboard");
    } catch (err) {
      console.error('Failed to copy: ', this.selectedPrompt, err);
    }
  }

  }
}




</script>







<style>
@import "./styles/main.css";

body {
    background: rgb(4, 1, 32);
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(4, 1, 32);
}

.main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px auto;
    max-width: 1000px;

}

.sidebar {
    background: inherit;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0px 20px 20px;
    height: 100vh;
    position: relative;
}

.content {
    padding: 20px;
    background: white;
    width: 100%;
    height: auto;
    margin: 20px;
    border-radius: 5px;
    min-height: 80vh;
}


.event {
    width: 200px;
    padding: 20px 20px 20px 20px;
    

    border-left: 1px solid rgb(255,255,255,0.3);


    border-radius: 0px 7px 7px 0px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;

}

.event-date {
    text-align: left;
    opacity: 0.5;
}

.name {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    position: absolute;
    top: 15.5px;
    left: 50px;
}

.marker {
    width: 6px;
    height: 6px;
    position: absolute;
    top: calc(50%-5px);
    left: 17.5px;
    background: white;
    border:none;
    border-radius: 100px;
}

.selected {
    background: rgb(255,255,255,0.3)
}

.year {
    font-size: 30px;
    color: rgb(255,255,255,0.5);
    flex-direction: row;
}

.title {
    font-weight: 900;
    font-size: 20px;
    
}

.explain {
    font-size: 10px;
    color: rgb(183, 87, 8);
    text-decoration: underline;
    position: relative;
    top: -2px;
}

.heading {
    opacity: 0.5;
    font-size: 12px;
    text-transform: uppercase;
}

.hamburger {
    width: 20px;
    height: 20px;

}

.bar{
    width: 100%;
    height: 2px;
    background: white;
    margin-bottom: 3px;
    border-radius: 20px;
}

.bar-bottom {
    margin-bottom: 0px;
}

.inset {
    font-size: 14px;
    border: 2px solid rgb(11, 0, 91);
    background: rgb(11,0,91,0.1);
    padding: 20px 20px;
    border-radius: 10px;
    margin: 30px auto;
}

.inset > .heading {
    opacity: 0.9;
    font-weight: 100;
}

#nav, a {
  color: white;
}

</style>